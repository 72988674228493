import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import store from 'src/redux';
import { actions as accountActions } from 'src/redux/modules/account';

const firebaseConfig = {
  apiKey: window.settings.FIREBASE_API_KEY,
  authDomain: window.settings.FIREBASE_AUTH_DOMAIN,
  projectId: window.settings.FIREBASE_PROJECT_ID,
  storageBucket: window.settings.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.settings.FIREBASE_MESSAGING_SENDER_ID,
  appId: window.settings.FIREBASE_APP_ID,
  measurementId: window.settings.FIREBASE_MEASUREMENT_ID,
};
interface IApp {
  auth?: ReturnType<typeof Firebase.auth>
  storage?: ReturnType<typeof Firebase.storage>
}
export const app: IApp = {
  auth: undefined,
  storage: undefined,
}
export const firebaseInitialize = () => {
  Firebase.initializeApp(firebaseConfig);
  app.storage = Firebase.storage();
  app.auth = Firebase.auth();

  app.auth?.onAuthStateChanged((user) => {
    store.dispatch(accountActions.authStateChanged.pending());
  });

}


const getAccountStorageRef = async () => {
  if (app.auth && app.auth.currentUser && app.storage) {
    return app.storage.ref(`users`).child(app.auth.currentUser.uid);
  } else {
    return null;
  }
};

const providers = {
  google: () => {
    const provider = new Firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });

    return provider;
  },
  apple: () => {
    const provider = new Firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    return provider;
  },
};

const getCredential = (providerId: string) => {
  switch (providerId) {
    case 'apple.com': return providers.apple();
    case 'google.com': return providers.google();
  }
}
export const getIdToken = (refreshed?: boolean) =>
  new Promise((resolve, reject) => {

    if (app.auth && app.auth.currentUser) {
      app.auth.currentUser
        .getIdToken(refreshed)
        .then(resolve)
        .catch(reject);
    } else {
      const unscible = app.auth?.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          currentUser
            .getIdToken(refreshed)
            .then(resolve)
            .catch(reject);
        } else {
          reject()
        }
        unscible?.()
      })
    }

  });
export const getIdTokenResult = (refreshed?: boolean) =>
  new Promise((resolve, reject) => {
    if (app.auth && app.auth.currentUser) {
      app.auth.currentUser
        .getIdTokenResult(refreshed)
        .then(resolve)
        .catch(reject);
    } else {
      const unscible = app.auth?.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          currentUser
            .getIdTokenResult(refreshed)
            .then(resolve)
            .catch(reject);
        } else {
          reject()
        }
        unscible?.()
      })
    }

  });

export const sendEmailVerification = async (url: string) => {
  if (app && app.auth) {
    if (app.auth.currentUser) {

      await app.auth.currentUser.sendEmailVerification(
        { url }
      );

      window.localStorage.setItem('emailForSignIn', app.auth.currentUser.email || '');

    } else {
      throw Error('Firebase not found auth user')
    }
  } else {
    throw Error('Firebase not found app auth')
  }
}

export interface ISignInEmailPasswordPayload {
  email: string;
  password: string;
}

export const signInEmailPassword = async ({ email, password }: ISignInEmailPasswordPayload) => {
  if (app && app.auth) {
    await app.auth.signInWithEmailAndPassword(email, password)
  } else {
    throw Error('Firebase not found app auth')
  }
}
export interface ISignUpEmailPasswordPayload {
  email: string;
  name?: string;
  password: string;
  avatar?:File;
}
export const signUpEmailPassword = async ({ email, password, name, avatar }: ISignUpEmailPasswordPayload) => {
  if (app && app.auth) {
    await app.auth.createUserWithEmailAndPassword(email, password)
    if (avatar) {
      const { photoURL } = await uploadAvatar({ file: avatar })
      await updateProfile({
        displayName: name,
        photoURL
      })
    } else {
      if (name){
        await updateProfile({
          displayName: name,
        })
      }

    }
  } else {
    throw Error('Firebase not found app auth')
  }
}

export const getUserProvider = () => {
  return app?.auth?.currentUser?.providerData?.[0]?.providerId
}


export interface IResetPasswordLinkPayload {
  email: string
}

export const resetPasswordLink = async ({ email }: IResetPasswordLinkPayload) => {
  if (app && app.auth) {
    await app.auth.sendPasswordResetEmail(email, {
      url: `${location.origin}/?modal=login&email=${email}`
    });

  } else {
    throw Error('Firebase not found app auth')
  }
}



export const sendChangePasswordLink = async () => {
  if (app && app.auth ) {
    if (!app.auth?.currentUser?.email) throw Error('sendChangePasswordLink: not found email')

    await app.auth.sendPasswordResetEmail( app.auth.currentUser.email, {
      url: `${location.origin}/?modal=login&email=${app.auth.currentUser.email}`
    });

  } else {
    throw Error('Firebase not found app auth')
  }
}


export interface IChangePasswordPayload {
  code: string;
  newPassword: string;
}

export const changePassword = async ({ code, newPassword }: IChangePasswordPayload) => {
  if (app && app.auth) {
    await app.auth.confirmPasswordReset(code, newPassword);
  } else {
    throw Error('Firebase not found app auth')
  }
}

export const signInEmailLink = async () => {
  if (app && app.auth) {

    if (app.auth.isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) throw Error('Firebase email not found');
      const user = await app.auth.signInWithEmailLink(email, window.location.href);

      window.localStorage.removeItem('emailForSignIn');

      return user
    } else {
      throw Error('Firebase email link not found')
    }
  } else {
    throw Error('Firebase not found app auth')
  }
}
export const signOut = () =>
  new Promise((resolve, reject) => {
    if (app.auth && app.auth) {
      app.auth.signOut().then(resolve).catch(reject);
    } else {
      reject();
    }
  });


export const updateEmail = (email: string) =>
  new Promise((resolve, reject) => {
    if (app.auth && app.auth.currentUser) {
      app.auth.currentUser.updateEmail(email).then(resolve).catch(reject);
    } else {
      reject();
    }
  });

export const verifyBeforeUpdateEmail = (email: string) =>
  new Promise((resolve, reject) => {
    if (app.auth && app.auth.currentUser) {
      app.auth.currentUser
        .verifyBeforeUpdateEmail(email)
        .then(resolve)
        .catch(reject);
    } else {
      reject();
    }
  });

export const updateProfile = (profile: {
  displayName?: string;
  photoURL?: string;
}) =>
  new Promise((resolve, reject) => {
    if (app.auth && app.auth.currentUser) {
      app.auth.currentUser
        .updateProfile(profile)
        .then(resolve)
        .catch(reject);
    } else {
      reject();
    }
  });

export const deleteAccount = async (payload: { password?: string }) => {
  if (app.auth && app.auth.currentUser) {
    const providerId = app.auth.currentUser?.providerData?.[0]?.providerId
    const email = app.auth.currentUser.email;
    if (!providerId) throw Error('Delete account: provider Id not found')

    if (providerId === 'password') {
      if (!email) throw Error('Delete account: user email not found');
      if (!payload?.password) throw Error('Delete account: params password not found');

      const passwordCredentials = Firebase.auth.EmailAuthProvider.credential(email || '', payload?.password || '');
      const userCredential = await app.auth.currentUser.reauthenticateWithCredential(passwordCredentials)

    } else {

      const credential = getCredential(providerId)

      if (!credential) throw Error('Delete account: credential not found');

      const userCredential = await app.auth.currentUser.reauthenticateWithPopup(credential)
    }


    const accountStorageRef = await getAccountStorageRef();

    if (accountStorageRef) {
      try {
        await accountStorageRef.child(`avatar`).delete();
      } catch (e) { }
    }

    await app.auth.currentUser.delete()

  } else {
    throw Error('Delete account: app auth not found')
  }
};

export const uploadAvatar = (params: { file: Blob }) =>
  new Promise<{ photoURL: string }>(async (resolve, reject) => {
    const accountStorageRef = await getAccountStorageRef();

    if (!accountStorageRef) {
      return reject();
    }

    try {
      const accountAvatarStorageRef = accountStorageRef.child(`avatar`);
      const task = accountAvatarStorageRef.put(params.file);
      const snapshot = await task;
      const photoURL = await snapshot.ref.getDownloadURL();
      await updateProfile({ photoURL });

      resolve({ photoURL });
    } catch (e) {
      reject(e);
    }
  });

export type TFirebaseProviders = keyof typeof providers;

export const auth = (provider: TFirebaseProviders) =>
  app.auth?.signInWithPopup(providers[provider]());

export { Firebase };

export interface IdTokenResult {
  /**
   * The Firebase Auth ID token JWT string.
   */
  token: string;
  /**
   * The ID token expiration time formatted as a UTC string.
   */
  expirationTime: string;
  /**
   * The authentication time formatted as a UTC string. This is the time the
   * user authenticated (signed in) and not the time the token was refreshed.
   */
  authTime: string;
  /**
   * The ID token issued at time formatted as a UTC string.
   */
  issuedAtTime: string;
  /**
   * The sign-in provider through which the ID token was obtained (anonymous,
   * custom, phone, password, etc). Note, this does not map to provider IDs.
   */
  signInProvider: string | null;
  /**
   * The type of second factor associated with this session, provided the user
   * was multi-factor authenticated (eg. phone, etc).
   */
  signInSecondFactor: string | null;
  /**
   * The entire payload claims of the ID token including the standard reserved
   * claims as well as the custom claims.
   */
  claims: {
    [key: string]: any;
  };
}
