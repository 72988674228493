import posthog from 'posthog-js'

export const posthogInitialize = ()=> {
  if (
    window.settings.POSTHOG_TOKEN &&
    window.settings.POSTHOG_API_HOST
  ) {
    posthog.init(window.settings.POSTHOG_TOKEN, {
      api_host: window.settings.POSTHOG_API_HOST,
      capture_pageview: false,
      autocapture: false,
    })
  }
}

export {
  posthog
}
