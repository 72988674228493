import { createPhaseAction } from 'src/helpers/redux';

import * as paymentServiceTypes from 'src/services/RomanticAI/payment/types';

export const PREFIX = 'payment';
export const SAGA = `${PREFIX}/saga`;

export const actions = {
  postCreateCheckoutSession: createPhaseAction<{
    dialogId?: number;
    reason?: string;
    is_old?: boolean;
    price_id: paymentServiceTypes.IPostCreateCheckoutSessionParams['price_id'];
  }>(`${SAGA}/postCreateCheckoutSession`),

  clearCheckoutSession: createPhaseAction<void>(`${SAGA}/clearCheckoutSession`),

  postHeartCreateCheckoutSession: createPhaseAction<{
    dialogId?: number;
    reason?: string;
    botName?: string;
    isPersonal?: boolean;
    price_id: paymentServiceTypes.IPostCreateCheckoutSessionParams['price_id'];
  }>(`${SAGA}/postHeartCreateCheckoutSession`),
  clearHeartCheckoutSession: createPhaseAction<void>(
    `${SAGA}/clearHeartCheckoutSession`
  ),

  getSubscription: createPhaseAction<void>(`${SAGA}/getSubscription`),

  getWallet: createPhaseAction<void>(`${SAGA}/getWallet`),
  getProducts: createPhaseAction<void>(`${SAGA}/getProducts`),
  postOrder: createPhaseAction<void>(`${SAGA}/postOrder`),
};
