import { useParams } from 'react-router-dom';
import { ECoinsPaywallReason } from 'src/services/analytics/events';
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';
import { useDialogSelect } from 'src/hooks/useDialog';
export {
  ECoinsPaywallReason
}
interface IModalPaywalHeartOpenParams {
  redirectPage?: string;
  redirectModal?: string;
  reason: ECoinsPaywallReason
}
interface IModalPaywalHeartQueryParams extends IModalPaywalHeartOpenParams{
  modal: 'paywall-heart';
  dialogId?: number;
  botName?: string;
  isPersonal?: boolean
}
export const useModalPaywallHeart = ()=> {
  const dialogHistory = useDialogSelect.dialogHistory()
  const bot = useDialogSelect.bot()
  const { chatId } = useParams()
  const isUser = useAccountSelect.isUser();

  const search = useSearchQuery<IModalPaywalHeartQueryParams>();
  const open = (params: IModalPaywalHeartOpenParams) => {
    if (!isUser) return;

    search.setQuery({
      modal: 'paywall-heart',
      dialogId: chatId ? dialogHistory?.id : undefined,
      botName: chatId ? bot?.name : '',
      isPersonal: chatId ? bot?.is_personal : undefined,
      reason: params.reason,
      redirectPage: params.redirectPage,
      redirectModal: params.redirectModal,
    });
  };

  const close = ()=>{
    search.removeQuery([
      'modal',
      'redirectModal',
      'redirectPage',
      'dialogId',
      'botName',
      'isPersonal',
      'reason',
    ])
  }

  return {
    dialogHistory,
    bot,
    chatId,
    open,
    close,
    ...search,
  }
}