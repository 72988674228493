/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { FacebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents } from 'src/services/analytics/events';
import { IResponse } from 'src/services/RomanticAI/http';
import * as botService from 'src/services/RomanticAI/bot';
import * as sessionService from 'src/services/RomanticAI/session';

import * as accountStore from 'src/redux/modules/account'
import * as dialogStore from 'src/redux/modules/dialog';
import * as moduleState from './state';
import { selectors } from './selectors';
import { actions } from './actions';
import { setters } from './index';


export default function* sagas() {
  yield all([
    takeLeading(
      actions.getSliders.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getSliders.pending>) {
        try {
          const sliders: IResponse<botService.ISlider> = yield call(
            botService.getSliders
          );

          yield put(setters.setSliders(sliders.data));

          yield put(actions.getSliders.fulfilled());
        } catch (error) {
          yield put(actions.getSliders.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getTraits.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getTraits.pending>) {
        try {
          const traits: IResponse<botService.ITraitsListItem[]> = yield call(
            botService.getTraits
          );

          yield put(setters.setTraits(traits.data));

          yield put(actions.getTraits.fulfilled());
        } catch (error) {
          yield put(actions.getTraits.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postCharacter.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postCharacter.pending>) {
        try {
          const { payload } = action;
          const bot: IResponse<botService.IBotCreated> = yield call(
            botService.postBot,
            payload
          );
          const createdBot = bot.data
          //@ts-ignore
          createdBot.is_personal = true;
          //@ts-ignore
          createdBot.name = createdBot.nickname
          //@ts-ignore
          yield put(dialogStore.actions.addCustomBot(createdBot));
          yield put(setters.setBotCreated(createdBot))
          yield put(actions.postCharacter.fulfilled());
        } catch (error) {
          yield put(actions.postCharacter.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.getBotSessionCreate.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getBotSessionCreate.pending>) {
        try {
          const { payload } = action;
          const sessionCreate: IResponse<botService.IGetBotSessionCreateData> = yield call(
            botService.getBotSessionCreate,
            payload,
          )
          yield put(setters.setBotSessionCreate(sessionCreate.data))
          yield put(actions.getBotSessionCreate.fulfilled())
        } catch (error) {
          yield put(actions.getBotSessionCreate.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.postBotSessionMode.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionMode.pending>) {
        try {
          const { payload } = action;
          const sessionMode: IResponse<botService.IPostBotSessionModeData> = yield call(
            botService.postBotSessionMode,
            payload,
          )
          yield put(setters.setBotSessionResult({ mode: payload.mode }))
          yield put(setters.setBotSessionMode(sessionMode.data));
          yield put(actions.postBotSessionMode.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionMode.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.postBotSessionGender.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionGender.pending>) {
        try {
          const { payload } = action;
          const sessionGender: IResponse<botService.IPostBotSessionGenderData> = yield call(
            botService.postBotSessionGender,
            payload,
          )
          yield put(setters.setBotSessionResult({ gender: payload.gender }))
          yield put(setters.setBotSessionGender(sessionGender.data));
          yield put(actions.getBotSessionCreate.fulfilled())
          yield put(actions.postBotSessionGender.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionGender.rejected(error as Error))
        }
      }
    ),

    takeLeading(
      actions.postBotSessionNickName.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionNickName.pending>) {
        try {
          const { payload } = action;
          const sessionNickName: IResponse<botService.IPostBotSessionNickNameData> = yield call(
            botService.postBotSessionNickName,
            payload,
          )
          yield put(setters.setBotSessionResult({ nickname: payload.nickname }))
          yield put(setters.setBotSessionNickName(sessionNickName.data));
          yield put(actions.postBotSessionNickName.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionNickName.rejected(error as Error))
        }
      }
    ),

    takeLeading(
      actions.postBotSessionFaceTraits.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionFaceTraits.pending>) {
        try {
          const { payload } = action;
          yield put(setters.setBotSessionResult({ face: payload.face_traits }))
          yield put(setters.setImageProcessingLimit(undefined));

          const sessionFaceTraits: IResponse<botService.IPostBotSessionFaceTraitsData> = yield call(
            botService.postBotSessionFaceTraits,
            payload,
          )
          const sessionCreate: moduleState.IBotCreateSession['create'] = yield select(selectors.sessionCreate)

          if (payload.create_bot_session_id === sessionCreate.create_bot_session_id) {
            yield put(setters.setBotSessionFaceTraits(sessionFaceTraits.data));
            yield put(actions.postBotSessionFaceTraits.fulfilled())
          } else {
            yield put(actions.postBotSessionFaceTraits.clear())
          }

        } catch (error) {
          const response = (error as {
            response: IResponse<botService.IPostBotSessionFaceTraitsError>
          })?.response

          if (response?.data?.reset_in) {
            yield put(setters.setImageProcessingLimit(response.data));
          }

          yield put(actions.postBotSessionFaceTraits.rejected(error as Error))
        }
      }
    ),

    takeLeading(
      actions.postBotSessionImage.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionImage.pending>) {
        try {
          const { payload } = action;
          yield put(setters.setBotSessionResult({ image_id: payload.image_id }))

          const sessionImage: IResponse<botService.IPostBotSessionImageData> = yield call(
            botService.postBotSessionImage,
            payload,
          )
          yield put(setters.setBotSessionImage(sessionImage.data));
          yield put(actions.postBotSessionImage.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionImage.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.postBotSessionPersonaFacts.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionPersonaFacts.pending>) {
        try {
          const { payload } = action;
          yield put(setters.setBotSessionResult({ persona_facts: payload.persona_facts }))

          const sessionPersonaFacts: IResponse<botService.IPostBotSessionPersonaFactsData> = yield call(
            botService.postBotSessionPersonaFacts,
            payload,
          )


          yield put(actions.postBotSessionPersonaFacts.fulfilled())
        } catch (error) {
          const response = (error as {
            response: IResponse<botService.IPostBotSessionPersonaFactsError>
          })?.response

          if (response?.data?.detail) {
            yield put(setters.setBotSessionPersonaFactsError(response?.data));
          }
          yield put(actions.postBotSessionPersonaFacts.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.postBotSessionPersonaTraits.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionPersonaTraits.pending>) {
        try {
          const { payload } = action;

          yield put(setters.setBotSessionResult({ persona: payload.persona_traits }))

          // if (payload.persona_type === 'upload') {
          //   const sessionPersonaTraits: IResponse<botService.IPostBotSessionPersonaTraitsData> = yield call(
          //     botService.postBotSessionPersonaTraits,
          //     {
          //       create_bot_session_id: payload.create_bot_session_id,
          //       persona_traits: payload.persona_traits,
          //       has_subscribe: payload.has_subscribe
          //     },
          //   )
          //   yield put(setters.setBotSessionPersonaTraits(sessionPersonaTraits.data));
          // }

          yield put(actions.postBotSessionPersonaTraits.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionPersonaTraits.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.sessionComplite.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionComplite.pending>) {
        try {
          const results: moduleState.ISessionResults = yield select(selectors.sessionResults)
          const botSession: moduleState.IBotCreateSession['create'] = yield select(selectors.sessionCreate);
          const personaType: moduleState.IBotCreateSession['personaType'] = yield select(selectors.sessionPersonaType);
          const isSubscription: boolean = yield select(accountStore.selectors.isSubscribled)



          const sessionPersonaTraits: IResponse<botService.IPostBotSessionPersonaTraitsData> = yield call(
            botService.postBotSessionPersonaTraits,
            {
              create_bot_session_id: botSession.create_bot_session_id,
              persona_traits: results.persona,
              has_subscribe: isSubscription,
              persona_type: personaType,
            },
          )

          yield put(setters.setBotSessionPersonaTraits(sessionPersonaTraits.data));

          if (personaType === 'generated') {
            const createdBot = {...sessionPersonaTraits.data}
            //@ts-ignore
            createdBot.is_personal = true;
            //@ts-ignore
            createdBot.name = results.nickname //createdBot.nickname
            //@ts-ignore
            yield put(dialogStore.actions.addCustomBot(createdBot));
          }


          if (personaType === 'upload') {
            const commit: IResponse<botService.IPostBotSessionUserImageCommitData> = yield call(
              botService.postBotSessionUserImageCommit,
              {
                create_bot_session_id: botSession.create_bot_session_id,
                has_subscribe: isSubscription
              },
            )

            yield put(setters.setBotSessionUserImageCommit(commit.data));

            const createdBot = {...commit.data}
            //@ts-ignore
            createdBot.is_personal = true;
            //@ts-ignore
            createdBot.name = results.nickname //createdBot.nickname
            //@ts-ignore
            yield put(dialogStore.actions.addCustomBot(createdBot));
          }

          const customBotCount:number = yield select(accountStore.selectors.customBotCount);
          yield put(accountStore.actions.customBotCountUpdate(customBotCount+1))

          yield put(actions.sessionComplite.fulfilled())
        } catch (error) {
          yield put(actions.sessionComplite.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.postBotSessionUserImage.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotSessionUserImage.pending>) {
        try {
          const { payload } = action;
          const sessionUserImage: IResponse<botService.IPostBotSessionUserImageData> = yield call(
            botService.postBotSessionUserImage,
            payload,
          )
          yield put(setters.setBotSessionResult({ user_image: payload.image }))
          yield put(setters.setBotSessionUserImage(sessionUserImage.data));
          yield put(actions.postBotSessionUserImage.fulfilled())
        } catch (error) {
          yield put(actions.postBotSessionUserImage.rejected(error as Error))
        }
      }
    ),
    takeLeading(
      actions.changeSessionResults.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.changeSessionResults.pending>) {
        try {
          yield put(setters.setBotSessionResult(action.payload))
          yield put(actions.changeSessionResults.fulfilled());
        } catch (error) {
          yield put(actions.changeSessionResults.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.clear.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.clear.pending>) {
        try {
          yield put(setters.setClear())
          yield put(actions.clear.fulfilled());
        } catch (error) {
          yield put(actions.clear.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.clearSession.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.clearSession.pending>) {
        try {
          yield put(setters.setClearSession())
          yield put(actions.clearSession.fulfilled());
        } catch (error) {
          yield put(actions.clearSession.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sessionStep.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionStep.pending>) {
        try {
          yield put(setters.setSessionStep(action.payload))
          yield put(actions.sessionStep.fulfilled());
        } catch (error) {
          yield put(actions.sessionStep.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sessionPersonaType.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionPersonaType.pending>) {
        try {
          yield put(setters.setSessionPersonaType(action.payload))
          yield put(actions.sessionPersonaType.fulfilled());
        } catch (error) {
          yield put(actions.sessionPersonaType.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sessionImageProcessingRetry.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionImageProcessingRetry.pending>) {
        try {
          const isSubscription: boolean = yield select(accountStore.selectors.isSubscribled)
          const sessionCreated: moduleState.IStateData['createSession']['create'] =
            yield select(selectors.sessionCreate)
          const results: moduleState.ISessionResults = yield select(selectors.sessionResults)

          yield put(actions.postBotSessionFaceTraits.pending({
            create_bot_session_id: sessionCreated.create_bot_session_id,
            face_traits: results.face,
            has_subscribe: isSubscription,
          }))

          yield put(actions.sessionImageProcessingRetry.fulfilled());
        } catch (error) {
          yield put(actions.sessionImageProcessingRetry.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.botShare.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.botShare.pending>) {
        try {
          const { share_code } = action.payload
          const bot: IResponse<botService.IGetBotShareData> = yield call(
            botService.getBotShare,
            { share_code }
          )
          if (bot.data.is_chat_exist) {


          } else {
            yield call(
              botService.postBotShare,
              { share_code }
            )
            //@ts-ignore
            yield put(dialogStore.actions.addCustomBot({
              is_personal: true,
              //@ts-ignore
              traits: [],
              ...bot.data,
              name: bot.data.nickname,
            }));
          }
          yield put(actions.botShare.fulfilled());
        } catch (error) {
          yield put(actions.botShare.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.shareBotTooltipClosed.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.botShare.pending>) {
        try {
          sessionService.setSession({ shareBotTooltipClosed: true })
          yield put(setters.setShareBotTooltipClosed())
          yield put(actions.shareBotTooltipClosed.fulfilled());
        } catch (error) {
          yield put(actions.shareBotTooltipClosed.rejected(error as Error));
        }
      }
    ),
  ]);
}
