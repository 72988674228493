import * as types from './types';
import { EVENTS, PurchaseEvent } from './types';
import { gtagAsync } from '../providers/GoogleTagManager';
import { posthog } from '../providers/Posthog'
import { getSession } from 'src/services/RomanticAI/session'

export * from './types'

/**
 * Docs:
 * @link https://www.notion.so/3157f68bc7224227ba97a42152b68edc?v=ebffbf3433a7407799cf42aba39bc752
 */
export const analyticsEvents = {

  /** При первом входе на сервис, если нет наших cookies */
  cohortEvent: async (payload?: types.ICohortEventPayload) => {
    // posthog.capture(EVENTS.cohort_event, payload)
    // await gtagAsync(EVENTS.cohort_event, payload)
  },

  /** При отказе/принятии условий сервиса */
  consentGranted: async (payload: types.IConsentGrantedPayload) => {
    await gtagAsync(EVENTS.consent_granted, payload)
  },

  /** Отправляется каждый раз когда пользователь открывает MyChats или Explore */
  screenShown: async (payload: types.IScreenShownPayload) => {
    await gtagAsync(EVENTS.screen_shown, payload)
  },

  /** Выход с экрана чата с ботом по клику на “Back” */
  leaveChat: async (payload: types.ILeaveChatPayload) => {
    await gtagAsync(EVENTS.leave_chat, payload)
  },

  /** Отправляется каждый раз когда пользователь входит в чат с ботом */
  startedChat: async (payload: types.IStartedChatPayload) => {
    await gtagAsync(EVENTS.started_chat, payload)
  },

  /** Отправка пользователем сообщения */
  userSentMessage: async (payload: types.IUserSentMessagePayload) => {
    posthog.capture(EVENTS.user_sent_message, payload)
    await gtagAsync(EVENTS.user_sent_message, payload)
  },

  /** Отправка ботом изображения */
  botSentImage: async (payload: types.IBotSentImagePayload) => {
    posthog.capture(EVENTS.bot_sent_image, payload)
    await gtagAsync(EVENTS.bot_sent_image, payload)
  },

  /** Бот отправил сообщений */
  botSentMessage: async (payload: types.IBotSentMessagePayload) => {
    await gtagAsync(EVENTS.bot_sent_message, payload)
  },

  /** В момент показа pop-up’ов */
  popUpShown: async (payload: types.IPopUpShownPayload) => {
    await gtagAsync(EVENTS.pop_up_shown, payload)
  },

  /** В момент показа paywall подписки */
  subscriptionPaywallShown: async (payload: types.ISubscriptionPaywallShownPayload) => {
    const session = getSession()

    posthog.capture(EVENTS.subscription_paywall_shown, payload)
    await gtagAsync(EVENTS.subscription_paywall_shown, {
      ...payload,
      ...session.analyticsUTM
    })
  },

  /** В момент перехода в платёжную систему */
  subscriptionCheckout: async (payload: types.ISubscriptionCheckoutPayload) => {
    posthog.capture(EVENTS.subscription_checkout, payload)
    await gtagAsync(EVENTS.subscription_checkout, payload)
  },

  /** В момент успешной регистрации нового пользователя */
  userRegistered: async (payload?: types.IUserRegisteredPayload) => {
    posthog.capture(EVENTS.user_registered, payload)
    await gtagAsync(EVENTS.user_registered, payload)
  },

  /** В момент авторизации ранее зарегистрированного пользователя */
  userLoggedIn: async (payload?: types.IUserLoggedInPayload) => {
    await gtagAsync(EVENTS.user_logged_in, payload)
  },

  /** Если показ PW показан и произошла оплата в чате с ботом */
  subscriptionPaid: async (payload: types.ISubscriptionPaidPayload) => {
    const { item, ...data } = payload;
    if (data.bot_id){
      posthog.capture(EVENTS.dialog_converted_subscription, { ...item, ...data })
    }
    await gtagAsync(EVENTS.subscription_paid, { ...item, ...data })
  },

  /** Пользователь успешно купил подписку. */
  subscriptionStarted: async (payload: types.IEcommercePayload) => {
    {
      const { items, ...data } = payload
      posthog.capture(PurchaseEvent, {
        ...data,
        ...items?.[0]
      })
    }
    await gtagAsync(PurchaseEvent, payload)
  },

  subscriptionStartedTest: async (payload: types.IEcommercePayload) => {
    const { items, ...data } = payload
    await gtagAsync('purchase_first_a', payload)
    await gtagAsync('purchase_first_b', {
      ...data,
      ...items?.[0]
    })
  },
  subscriptionStartedTestHighvalue: async (payload: types.IEcommercePayload) => {
    const { items, ...data } = payload
    await gtagAsync('purchase_highvalue_a', payload)
    await gtagAsync('purchase_highvalue_b', {
      ...data,
      ...items?.[0]
    })
  },
  /** Пользователь успешно купил сердца. */
  walletHeartPaidSuccess: async (payload: types.IEcommercePayload) => {
    posthog.capture(PurchaseEvent, payload)
    await gtagAsync(PurchaseEvent, payload)
  },

  /** Пользователь нажал на кнопку в чате send me picture */
  sendMeAPictureButtonClick: async (payload?: types.ISendMeAPictureButtonClick) => {
    posthog.capture(EVENTS.send_me_a_picture_button_click, payload)
    await gtagAsync(EVENTS.send_me_a_picture_button_click, payload)
  },

  createChatbot: async (payload?: types.ICreateChatbotPayload) => {
    posthog.capture(EVENTS.create_chatbot, payload)
    await gtagAsync(EVENTS.create_chatbot, payload)
  },

  coinsPaid: async (payload: types.ICoinsPaidPayload) => {
    const { item, ...data } = payload
    if (data.bot_id){
      posthog.capture(EVENTS.dialog_converted_hearts, { ...data, ...item })
    }
    await gtagAsync(EVENTS.coins_paid, { ...data, ...item })
  },

  coinsPaywallShown: async (payload?: types.ICoinsPaywallShownPayload) => {
    posthog.capture(EVENTS.coins_paywall_shown, payload)
    await gtagAsync(EVENTS.coins_paywall_shown, payload)
  },

  purchasedUnblurPhoto: async (payload?: types.IPurchasedUnblurPhotoPayload) => {
    posthog.capture(EVENTS.purchased_unblur_photo, payload)
    await gtagAsync(EVENTS.purchased_unblur_photo, payload)
  },

  userOpenedStore: async (payload: types.IUserOpenedStore) => {
    posthog.capture(EVENTS.user_opened_store, payload)
    await gtagAsync(EVENTS.user_opened_store, payload)
  },

  userPurchasedStore: async (payload: types.IUserPurchasedStore) => {
    posthog.capture(EVENTS.user_purchased_store, payload)
    await gtagAsync(EVENTS.user_purchased_store, payload)
  },

  shareBotButtonClick: async (payload: types.IShareBotButtonClick) => {
    posthog.capture(EVENTS.share_bot_button_click, payload)
    await gtagAsync(EVENTS.share_bot_button_click, payload)
  },

  pwaPopupShow: async () => {
    posthog.capture(EVENTS.pwa_popup_show)
  },

  pwaInstalled: async () => {
    posthog.capture(EVENTS.pwa_installed)
  },

  pwaPushActivated: async () => {
    posthog.capture(EVENTS.pwa_push_activated)
  },

  searchInitiated: async (payload: types.ISearchInitiated) => {
    posthog.capture(EVENTS.search_initiated, payload);
    await gtagAsync(EVENTS.search_initiated, payload);
  },

  reactionSent: async (payload: types.IReactionSent) => {
    posthog.capture(EVENTS.reaction_sent, payload);
  },

  generate: async (payload: types.IGenerate) => {
    posthog.capture(EVENTS.generate, payload);
    await gtagAsync(EVENTS.generate, payload);
  },

};