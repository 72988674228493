import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';
import * as sessionService from 'src/services/RomanticAI/session';
import * as userService from 'src/services/RomanticAI/user';
import * as paymentServiceTypes from 'src/services/RomanticAI/payment/types';
import { isPWAInstalled } from 'src/utils/isPWAInstalled';

export interface IAccountProfile {
  displayName?: string;
  email?: string;
  photoUrl?: string;
  provider?: string;
}
export interface IStateData {
  clientToken?: string;
  subscriptionEmail?: string;
  emailVerification?: {
    email: string;
    required: boolean;
  };
  idToken?: string;
  confirmAdult?: boolean;
  profile?: IAccountProfile;
  user?: userService.IGetUserData;
  wallet?: paymentServiceTypes.IGetWalletData;
  subscription?: paymentServiceTypes.IGetSubscriptionData;
  analyticsUTM?: sessionService.ISession['analyticsUTM'];
  superOffer?:sessionService.ISession['superOffer']
  appInstall?: sessionService.ISession['appInstall']
  appNotifyAccept?: sessionService.ISession['appNotifyAccept']
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = sessionService.getSession();
  const getAppNotifyAccept = ()=>{
    if (window?.Notification?.permission==='default'){
      return undefined
    }
    if (window?.Notification?.permission==='denied'){
      return false
    }

    if (window?.Notification?.permission=== 'granted'){
      return true
    }

    return false;
  }
  return {
    clientToken: session.clientToken,
    idToken: session.firebaseIdToken,
    confirmAdult: session.confirmAdult,
    profile: {
      displayName: session?.displayName,
      photoUrl: session.photoUrl,
      email: session.email,
    },
    wallet: undefined,
    user: undefined,
    subscription: session.subscription,
    analyticsUTM: session.analyticsUTM,
    superOffer: session.superOffer,
    appInstall: isPWAInstalled(),
    appNotifyAccept: getAppNotifyAccept(),
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
