import axios, { AxiosResponse, AxiosPromise, InternalAxiosRequestConfig } from 'axios';
import { getSession } from './session';
import jsonToFormData from 'json-form-data'

export const baseURL = window.settings.API_URL;
export const baseURLV3 = window.settings.API_URL_V3;

export type IResponse<Data> = AxiosResponse<Data>;
export type IResponseError<Data> = { response?: AxiosResponse<Data>};
export type HTTPPromise<Data> = AxiosPromise<Data>;

export const http = axios.create({
  baseURL: baseURL,
  headers: {
    'X-User-Agent': 'web:1.0.0:null:null',
  },
});
export const apiV3 = axios.create({
  baseURL: baseURLV3,
  headers: {
    'X-User-Agent': 'web:1.0.0:null:null',
  },
});

const session = (config: InternalAxiosRequestConfig<any>) => {
  const session = getSession();

  config.headers = config.headers || {};

  if (session?.firebaseIdToken) {
    config.headers['Authorization'] = `Bearer ${session.firebaseIdToken}`;
  } else {
    if (session.clientToken) {
      config.headers['Authorization'] = `Bearer ${session.clientToken}`;
    }
  }

  if (
    session?.firebaseIdToken &&
    (session?.clientToken || session.authClientToken)
  ) {
    config.headers['X-Old-Authorization'] = `Bearer ${
      session.authClientToken || session.clientToken
    }`;
  }

  return config;
}

// export const DataFormInterceptorMetodKey = '_method'
const recursiveFindFiles = (
  data: object | string | Array<unknown> | Blob | File
): boolean => {
  //@ts-ignore
  const isBlob = data instanceof Blob
  //@ts-ignore
  const isFile = data instanceof File
  const isFileObject = isBlob || isFile

  if (isFileObject) {
    return true
  }
  if (Array.isArray(data)) {
    return Boolean(data.find(recursiveFindFiles))
  }

  if (data !== null && typeof data === 'object') {
    //@ts-ignore
    return Boolean(Object.keys(data).find((k) => recursiveFindFiles(data[k])))
  }

  return false
}
export const DataFormInterceptorRequestFullfiled = (
  config: InternalAxiosRequestConfig
) => {
  config.headers = config.headers || {}
  const dataFormMethods = ['POST', 'PUT', 'PATCH']
  const isMethodDataForm = dataFormMethods.includes(
    config.method?.toUpperCase() as string
  )

  if (isMethodDataForm) {
    if (config.data) {
      const hasPropertyFile = recursiveFindFiles(config.data)

      if (hasPropertyFile) {
        const formData = jsonToFormData(config.data, {
          initialFormData: new FormData(),
          showLeafArrayIndexes: true,
          includeNullValues: false,
          // mapping: function(value) {
          //     if (typeof value === 'boolean') {
          //         return +value ? '1': '0';
          //     }
          //     return value;
          // }
        })

        // formData.append(
        //   DataFormInterceptorMetodKey,
        //   config.method?.toLowerCase() as string
        // )
        config.method = 'POST'
        config.data = formData
        config.headers['Content-Type'] =
          'multipart/form-data; boundary=something'
      }
    }
  }

  return config
}
http.interceptors.request.use(session);
apiV3.interceptors.request.use(session);
apiV3.interceptors.request.use(DataFormInterceptorRequestFullfiled);
