import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';

import { SubcribleBanner } from 'src//components/SubcribleBanner';
import { CustomBotBanner } from 'src//components/CustomBotBanner';
import { Icon, IIconProps } from 'src/components/Icon';

import { useMediaPhone } from 'src/hooks/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styles from './Navigation.module.scss';
import { useAccountSelect } from 'src/hooks/useAccount';
import { SocialPanel  } from 'src/components/SocialPanel';
import { ab_subscription_paywall_1_15_0_group } from 'src/services/RomanticAI/user';
import { AppInstall } from '../AppInstall';
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import queryString from 'query-string';

const sx = classNames.bind(styles);

interface INavItemProps extends NavLinkProps {
  icon?: IIconProps['name'];
  iconProps?: IIconProps;
  label?: string;
  query?: Record<string, string>
  to: string;
}

export const NavItem = (props: INavItemProps) => {
  const { icon, iconProps, to, label, query, onClick } = props;
  const search = useSearchQuery();

  const getActive = (isActive: boolean)=>{
    if (query && isActive){
      return Object.keys(query).find((prop)=>search.query[prop] === query[prop])
    }

    return isActive

  }

  return (
    <NavLink
      to={{
        pathname:to,
        search: query? queryString.stringify(query): undefined
      }}
      className={({ isActive }) => sx('item', { active: getActive(isActive) })}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
    >
      {icon && (
        <div className={sx('item-icon')}>
          <Icon {...iconProps} name={icon} />
        </div>
      )}
      {label && <div className={sx('item-label')}>{label}</div>}
    </NavLink>
  );
};

const useIsShowNavigation = ()=>{
  const isPhone = useMediaPhone();
  const location = useLocation();
  const isChat = location.pathname === '/chats';
  const isGeneratePhoto = location.pathname === '/generate-photo';
  const isGallery = location.pathname === '/gallery';
  const isCharacters = location.pathname.includes('/characters/');
  const isExplore = location.pathname === '/';

  const isView = !isPhone && [
    isExplore,
    isChat,
    isCharacters,
    isGeneratePhoto,
    isGallery
  ].includes(true);

  return isView
}

export const Navigation = () => {
  const pageRef = React.useRef<HTMLDivElement | null>(null);
  const isView = useIsShowNavigation()
  const user = useAccountSelect.user()
  const isSubscribled = useAccountSelect.isSubscribled();
  const isSuperCustom = user?.custom_bot === 'new_custom';
  const analyticsUTMRetargeting = useAccountSelect.analyticsUTMRetargeting();
  const isDuscount = !isSubscribled

  return (
    <SwitchTransition>
      <CSSTransition
        key={String(isView)}
        nodeRef={pageRef}
        timeout={75}
        classNames={'page'}
        unmountOnExit
      >
        {() => (
          <div ref={pageRef} className={`page`}>
            {isView && (
              <div ref={pageRef} className={sx('container')}>
                <div className={sx('list')}>
                  <NavItem icon="Home" to="/" label="Explore" />
                  <NavItem icon="Messages" to="/chats" label="Chats" />
                  {user?.ab_photo_romantic === 'ab_photo_romantic_b' && (
                    <>
                    <NavItem icon="Camera" to="/generate-photo" query={{tab:'traits'}} label="Generate Photo" />
                    <NavItem icon="Gallery" to="/generate-photo" query={{tab:'gallery'}} label="Gallery" />
                    </>
                  )}

                </div>
                <div className={sx('banners')}>
                  {
                    (!isSuperCustom || isDuscount) && (
                      <CustomBotBanner />
                    )
                  }
                  <SubcribleBanner />
                  <AppInstall/>
                </div>
                <div className={sx('footer')}>
                  <SocialPanel />
                </div>
              </div>
            )}
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};
