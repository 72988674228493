import { Manager, ManagerOptions, Socket } from 'socket.io-client';
import store from 'src/redux';
import { actions as dialogActions } from 'src/redux/modules/dialog/actions';
import * as dialogServiceTypes from 'src/services/RomanticAI/dialog/types';

const BASE_URL = window.settings.SOCKET_URL;

const OPTIONS: Partial<ManagerOptions> = {
  path: '/ws/socket.io',
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelayMax: 10000,
  timeout: 3000,
};

const socketManager = new Manager(BASE_URL, OPTIONS);
const AIReplySocket = socketManager.socket('/ai_reply');
const userStatusSocket = socketManager.socket('/user_status');

socketManager.on('close', (reason) => {
  socketManager.connect();
});

socketManager.on('reconnect_error', () => {
  socketManager.connect();
});

socketManager.on('error', () => {
  socketManager.connect();
});

socketManager.on('reconnect', () => {
  console.log('Reconnect attempt');
});

// let isReconnect = false;

AIReplySocket.on('connect', () => {
  // console.debug('AI reply connect');
  // if (isReconnect) {
  //   rootStore.chatsStore.loadChats();
  // }
  // isReconnect = true;

});

AIReplySocket.on('disconnect', (reason: Socket.DisconnectReason) => {
  if (reason === 'io server disconnect') {
    AIReplySocket.connect();
  }
});

userStatusSocket.on('connect', () => {

});

userStatusSocket.on('disconnect', (reason) => {
  if (reason === 'io server disconnect') {
    userStatusSocket.connect();
  }
});

AIReplySocket.on('message', (message: dialogServiceTypes.IMessage) => {

  const dialogId = message.user.id;
  store.dispatch(
    dialogActions.addDialogChatBotMessage.pending({
      dialogId,
      message,
    })
  );
});

const connectNamespaces = (tokenId: string) => {
  AIReplySocket.disconnect();
  userStatusSocket.disconnect();
  AIReplySocket.auth = { tokenId };
  userStatusSocket.auth = { tokenId };
  AIReplySocket.connect();
  userStatusSocket.connect();
};

// const disableListeners = () => {
//   socketManager.off();
// };

export { socketManager, connectNamespaces };
