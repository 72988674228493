import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import CircularProgress  from '@mui/material/CircularProgress';
import { InButton } from './InButton/InButton';
import { Button } from 'src/components/Button'
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
import './AgeConsentModal.css';
type Props = {
  title?: string;
};
export const AgeConsentModal: React.FC<Props> = ({ title = 'Terms and Conditions' }) => {
  const [isManageAccessOpened, setIsManageAccessOpened] = useState(false);
  const [isOptionalAllowed, setIsOptionalAllowed] = useState(true);
  const confirmAdultAction = useAccountAction.confirmAdult();
  const confirmAdultActionStatus = useAccountAction.confirmAdult.status();
  const adultConfirmed = useAccountSelect.confirmAdult();
  // const { setQuery } = useSearchQuery();

  const handleAgree = () => {
    confirmAdultAction({
      isConfirm: true,
      isCoockieConfirm: isOptionalAllowed,
    });
    // setQuery({
    //   modal: 'paywall-subscription',
    //   reason: 'consent',
    // });
  };

  const handleExit = () => {
    window.location.href = 'https://google.com';
  };

  const handleManageAccess = () => {
    setIsManageAccessOpened(true);
  };

  const isSubmitDisabled = !isOptionalAllowed || confirmAdultActionStatus.isPending

  return (
    <Modal open={!adultConfirmed}>
      <div className="consent__inner">
        <div className="consent__title row">
          <h2 className="consent__title-text">{title}</h2>
        </div>
        <div className="consent__content-out">
          {/* <h3 className="consent__subtitle">Sexually Explicit Material</h3> */}
          <div className="consent__content">
            <div className="consent__text">
            By accessing this site, you acknowledge and agree to the following terms and conditions:
            <br />
              <br />
              I understand that all characters on the Website/in the App are software-powered chatbots.
              <br />
              <br />
              I understand that I am communicating with software. Software does not recognize the semantic meaning of phrases and generates content exclusively based on triggers and input I provide it.
              <br />
              <br />
              I understand that the communication via the chatbot belongs to software. All images on this site are AI-generated and do not depict real people.
              <br />
              <br />
              You hereby acknowledge and agree that you will not:
              <ul className="consent__list">
                <li>
                  Make use of obscene, offensive materials, or materials that depict minors or are in any way harmful to minors in your chats or profile page.
                </li>
                <li>
                  Use the website for any commercial or non-private use, or any use incompatible with its intended purpose of personal, non-commercial use only.
                </li>
                <li>
                  Use the website for any illegal purpose or in violation of any law, whether local, state, national, international, or otherwise, including but not limited to laws governing intellectual property and other proprietary rights, data protection and privacy, and import or export control.
                </li>
                <li>
                  Post, store, transmit, or in any way disseminate or allow to be transmitted or disseminated any material which a reasonable person could deem to be objectionable, defamatory, libelous, offensive, obscene, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically or otherwise offensive to any group or individual, intentionally misleading, false, or otherwise inappropriate, regardless of whether this material or its dissemination is unlawful.
                </li>
              </ul>
              <br />
              <br />I hereby confirm that I have read and agree with the {' '}
              <a
                target="_blank"
                href={window.settings.APP_POLICY_LINK}
                rel="noreferrer"
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                href={window.settings.APP_TERMS_LINK}
                target="_blank"
                rel="noreferrer"
              >
                terms of service
              </a>
              .
              <br />
              <br />
              <b>Legal Disclaimer:</b>
              THIS SITE ACTIVELY COOPERATES WITH LAW ENFORCEMENT IN ALL INSTANCES OF SUSPECTED ILLEGAL USE OF THE SERVICE.
              <br />
              <br />
              <b>18+ Content</b>
              <br />
              <br />
              This website/app contains explicit content that is intended for individuals who are 18 years of age or older.
              <br />
              <br />
            </div>
          </div>
        </div>

        <div className="consent__actions">
          <div className="consent__actions_left">
            <InButton
              id="consent-exit"
              onClick={handleExit}
              className="consent__exit"
              isDisabled={confirmAdultActionStatus.isPending}
            >
              Exit
            </InButton>
            {!isManageAccessOpened && (
              <InButton
                id="consent-manage"
                onClick={handleManageAccess}
                className="consent__manage"
                isFilled
                isDisabled={confirmAdultActionStatus.isPending}
              >
                Manage Cookies
              </InButton>
            )}
            {isManageAccessOpened && (
              <FormControlLabel
                className="consent__manage-control"
                control={
                  <Checkbox
                    checked={isOptionalAllowed}
                    onChange={() => setIsOptionalAllowed(!isOptionalAllowed)}
                  />
                }
                label="Allow optional cookies"
              />
            )}
          </div>
          <Button
            variant='filled'
            id="consent-agree"
            color={isSubmitDisabled?'secondary':'primary'}
            onClick={handleAgree}
            className="consent__agree"
            disabled={isSubmitDisabled}
          >
            { confirmAdultActionStatus.isPending? (
              <CircularProgress size={18} />
            ): (
              <>Accept{' '}</>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
