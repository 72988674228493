import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import './Spinner.css';

type Props = {
  withLayout?: boolean;
};

export const Spinner = ({ withLayout = true }: Props) => {
  const [minHeight, setMinHeight] = useState(1000);
  useEffect(() => {
    setMinHeight(document.documentElement.offsetHeight);
  }, []);
  const innerSpinner = (
    <div className="spinner">
      <CircularProgress size={80} color="secondary" />
    </div>
  );
  if (!withLayout) {
    return innerSpinner;
  }

  return (
    <div className="spinner-layout" style={{ minHeight }}>
      {innerSpinner}
    </div>
  );
};
