import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { FacebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents } from 'src/services/analytics/events';
import { IResponse } from 'src/services/RomanticAI/http';
import * as giftService from 'src/services/RomanticAI/gift';
import * as Dialog from 'src/redux/modules/dialog'
import * as Account from 'src/redux/modules/account';
import { setSession } from 'src/services/RomanticAI/session';
import { actions } from './actions';
import { setters } from './index';
import { selectors } from './selectors';
import { IStateData } from './state';

export default function* sagas() {
  yield all([
    takeLeading(
      actions.getGift.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getGift.pending>) {
        try {
          const giftState:IStateData['gift'] = yield select(selectors.gift)

          if (giftState){
            yield put(actions.getGift.fulfilled());
            return;
          }

          const gift: IResponse<giftService.GetGiftData> = yield call(
            giftService.getGift
          );

          yield put(setters.setGift(gift.data));

          yield put(actions.getGift.fulfilled());
        } catch (error) {
          yield put(actions.getGift.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postGift.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postGift.pending>) {
        const { gift_id, bot_id, bot_name, item } = action.payload
        try {
          const gift: IResponse<giftService.IPostGiftData> = yield call(
            giftService.postGift,
            {
              gift_id,
              bot_id,
            }
          );
          const wallet:Account.IStateData['wallet'] = yield select(Account.selectors.wallet)

          if (wallet){
            yield put(Account.actions.changeWalletBalance.pending({
              balance: wallet.balance - item.cost
            }))
          }

          analyticsEvents.userPurchasedStore({
            item_price: item.cost,
            item_name: item.name,
            bot_name: bot_name,
          })
          yield put(Dialog.actions.appendChatUserMessage.pending({
            dialogId: action.payload.bot_id,
            messages: gift.data.messages
          }))

          yield put(actions.postGift.fulfilled());
        } catch (error) {
          yield put(actions.postGift.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.clearPostGift.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.clearPostGift.pending>) {
        try {

          yield put(setters.setClearPostGift());
          yield put(actions.clearPostGift.fulfilled());
        } catch (error) {
          yield put(actions.clearPostGift.rejected(error as Error));
        }
      }
    ),
    // takeLeading(
    //   actions.tooltipHidden.pending.toString(),
    //   function* (action: ActionPalyoad<typeof actions.tooltipHidden.pending>) {
    //     try {

    //       yield put(setters.setTooltipHidden());
    //       setSession({ chatStoreTooltipClosed: true })
    //       yield put(actions.tooltipHidden.fulfilled());
    //     } catch (error) {
    //       yield put(actions.tooltipHidden.rejected(error as Error));
    //     }
    //   }
    // )
  ]);
}
