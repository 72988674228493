import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useSearchQuery = <Query extends Record<any, any>>() => {
  const navigate = useNavigate();
  const location = useLocation();
  const arrayFormat = 'comma';

  const query = React.useMemo(() => {
    return queryString.parse(location.search, { arrayFormat }) as Query;
  }, [location.search]);

  const setQuery = React.useCallback(
    (toBeUpdatedQuery: Query, replace = false) => {
      const mergedQuery = { ...query, ...toBeUpdatedQuery } as Query;
      const mergedQueryString = queryString.stringify(mergedQuery, {
        arrayFormat,
      });
      const pathname = window.location.pathname;
      navigate(
        {
          pathname: pathname,
          search: mergedQueryString,
        },
        { replace }
      );
    },
    [query]
  );
  const removeQuery = React.useCallback(
    (toBeRemovedQuery: string[], replace = false) => {
      const mergedQuery = { ...query };

      toBeRemovedQuery.forEach((prop) => {
        delete mergedQuery[prop];
      });

      const mergedQueryString = queryString.stringify(mergedQuery, {
        arrayFormat,
      });
      const pathname = window.location.pathname;
      navigate(
        {
          pathname: pathname,
          search: mergedQueryString,
        },
        { replace }
      );
    },
    [query]
  );

  return {
    query,
    setQuery,
    removeQuery,
  };
};
