import { http } from 'src/services/RomanticAI/http';
import * as types from './types';

export * from './types';

export const postCreateCheckoutSession = (
  params: types.IPostCreateCheckoutSessionParams
) =>
  http.post<types.IPostCreateCheckoutSessionData>(
    '/payments/create-checkout-session',
    params
  );

export const getSubscription = () =>
  http.get<types.IGetSubscriptionData>('/payments/subscription');

export const getWallet = () =>
  http.get<types.IGetWalletData>('/payments/wallet');

export const getProducts = (provider: string) =>
  http.get<types.IGetProductsData>(`/payments/${provider}/product`);

export const postOrder = (provider: string, product_id: number) =>
  http.post<types.IPostOrderData>(`/payments/${provider}/order`, { product_id });

export const postOrderCapture = (provider: string, order_id: string) =>
  http.post<{ error_code: string }>(`/payments/${provider}/order/capture`, { order_id });

export const deleteSubscription = () =>
  http.delete(`/payments/subscription`);

