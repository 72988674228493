import FacebookPixel from '@juanlatorre/react-facebook-pixel';

const FACEBOOK_PIXEL_ID = window.settings.FACEBOOK_PIXEL_ID;

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

export const facebookPixelInitialize = ()=>{
  if (FACEBOOK_PIXEL_ID) {
    FacebookPixel.init(FACEBOOK_PIXEL_ID);
  }
}

// ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

export { FACEBOOK_PIXEL_ID, FacebookPixel };
