import React from 'react';
import { SearchAsyncModal } from 'src/modals/SearchAsyncModal';
import { AgeConsentModal } from 'src/modals/AgeConsentModal';
// import { ResubscriptionAlert } from 'src/modals/resubscription';
import { Spinner } from 'src/components/Spinner';
import { useDialogAction } from 'src/hooks/useDialog';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
import { useOutlet, useLocation } from 'react-router-dom';
import {
  SwitchTransition,
  Transition,
  CSSTransition,
} from 'react-transition-group';
import { Header } from 'src/components/Header';
import { NavigationMobile } from 'src/components/NavigationMobile';
import { Navigation } from 'src/components/Navigation';
import styles from './RootLayout.module.scss';
import { PaywallSuperOfferAsync } from 'src/modals/paywall-subscription/PaywallSuperOffer/PaywallSupperOfferAsync';
import { usePaymentAction } from 'src/hooks/usePayment';
import { useModalAppInstall } from 'src/hooks/route/useModalAppInstall';

export const RootLayout = () => {
  const location = useLocation();
  const isClient = useAccountSelect.isClient()
  const modalInstallApp = useModalAppInstall()
  const pageRef = React.useRef<HTMLDivElement | null>(null);
  const outlet = useOutlet();
  const sessionSetupStatus = useAccountAction.sessionSetup.status()
  const appReady = useAccountAction.appReady();
  const isFulfiled = sessionSetupStatus.isFulfilled
  const getProducts = usePaymentAction.getProducts()

  React.useEffect(() => {
    if (modalInstallApp.isNeedNotify) {
      modalInstallApp.openNotifyAlert()
    }
  }, [modalInstallApp.isNeedNotify])

  React.useEffect(() => {
    if (isClient && isFulfiled) {
      appReady();
      getProducts()
    }
  }, [isClient, isFulfiled]);

  if (!isFulfiled) return <Spinner />;

  return (
    <div className={styles.main}>
      <Header />
      <AgeConsentModal />
      <SearchAsyncModal />
      {/* <ResubscriptionAlert /> */}
      {/* <PaywallSuperOfferAsync /> */}

      <Navigation />


      <div id='root-layout-scroll' className={styles.scrollbar}>
        <div className={styles.content}>
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              nodeRef={pageRef}
              timeout={75}
              classNames={'page'}
              unmountOnExit
            >
              {() => (
                <div ref={pageRef} className={`page`}>
                  {outlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <NavigationMobile />
    </div>
  );
};

export default RootLayout;
