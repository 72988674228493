import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';

import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';
import { analyticsEvents } from 'src/services/analytics/events';

import styles from './SubcribleBanner.module.scss';

const sx = classNames.bind(styles);

export interface ISubcribleBannerProps {
  variant?: 'inline';
  text?: string;
  isViewInfo?: boolean;
}

export const SubcribleBanner = (props: ISubcribleBannerProps) => {
  const { variant, text = 'Get more with', isViewInfo = false } = props;
  const isUser = useAccountSelect.isUser();
  const isSubscribled = useAccountSelect.isSubscribled();
  const subscription = useAccountSelect.subscription();
  // const isOldServiceSubscription = useAccountSelect.isOldServiceSubscription();
  const { setQuery } = useSearchQuery();

  const handleOpenPaywall = () => {
    setQuery({
      modal: 'paywall-subscription', reason: 'self',
    });
  };

  if (isSubscribled) {
    if (!isViewInfo) return <></>;

    // if (isOldServiceSubscription) {
    //   return (
    //     <div className={sx('container')}>
    //       <div className={sx('title')}>
    //         <span className={sx('text')}>
    //           👑 Premium {variant === 'inline' ? 'subscription' : ''} till{' '}
    //           {subscription?.end_datetime ? format(
    //             new Date(subscription?.end_datetime as number),
    //             'dd.MM.yyyy'
    //           ) : 'lifetime'}
    //         </span>
    //       </div>
    //       <div className={sx('subtitle')}>
    //         It won't be renewed. We will remind you to update your payment
    //         details in 3 days before your subscription expires.
    //       </div>
    //     </div>
    //   );
    // }
    if (subscription?.payment_provider === 'stripe') {


      return (
        <div className={sx('container', variant)}>
          <div className={sx('title')}>
            <span className={sx('text')}>
              Premium {variant === 'inline' ? 'subscription' : ''}{' '}
              {subscription?.end_datetime ? format(
                new Date(subscription?.end_datetime as number),
                'dd.MM.yyyy'
              ) : 'lifetime'}
            </span>
          </div>
          <a
            href={`${window.settings.MIRROR_CHECKOUT_SESSION_URL}?redirect=subscription-edit`}
            target="_blank"
            rel="noreferrer"
            className={sx('link')}
          >
            <Button
              size="sm"
              variant="filled"
              color="secondary"
              className={sx('editButton')}
            >
              Edit
            </Button>
          </a>
        </div>
      );
    }

    if (subscription?.payment_provider === 'paypal') {
      return (
        <div className={sx('container', variant)}>
          <div className={sx('title')}>
            <span className={sx('text')}>
              Premium {variant === 'inline' ? 'subscription' : ''}{' '}
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={sx('container', variant)}>
      <div className={sx('title')}>
        <span className={sx('text')}>{text}</span>
        <span className={sx('icon')}>
          <Icon name="Crown" />
        </span>
        <span className={sx('grade')}>Premium</span>
      </div>

      <Button className={sx('button')} onClick={handleOpenPaywall}>
        Subscribe
      </Button>
    </div>
  );
};
