import { EBotSessionMode } from "src/services/RomanticAI/bot/types";
import type  * as Generate from 'src/services/RomanticAI/photo-generation/types'

export const PurchaseEvent = 'purchase'

export interface IEcommercePayload {
  //номер заказа
  transaction_id: string;
  currency: string;
  value: number;
  items?: IEcommercePayloadItem[]
}
export interface IEcommercePayloadItem {
  item_category: string;
  item_id: string | number;
  item_name: string;
  item_variant?: string;
  price: number;
  quantity: number;
}

export enum EVENTS {
  bot_sent_image = 'bot_sent_image',
  bot_sent_message = 'bot_sent_message',
  cohort_event = 'cohort_event',
  coins_paid = 'coins_paid',
  coins_paywall_shown = 'coins_paywall_shown',
  consent_granted = 'consent_granted',
  create_chatbot = 'create_chatbot',
  leave_chat = 'leave_chat',
  pop_up_shown = 'pop_up_shown',
  purchased_unblur_photo = 'purchased_unblur_photo',
  screen_shown = 'screen_shown',
  send_me_a_picture_button_click = 'send_me_a_picture_button_click',
  started_chat = 'started_chat',
  subscription_checkout = 'subscription_checkout',
  subscription_paid = 'subscription_paid',
  dialog_converted_subscription = 'dialog_converted_subscription',
  dialog_converted_hearts = 'dialog_converted_hearts',
  subscription_paywall_shown = 'subscription_paywall_shown',
  subscription_started = 'subscription_started',
  user_logged_in = 'user_logged_in',
  user_registered = 'user_registered',
  user_sent_message = 'user_sent_message',
  user_opened_store = 'user_opened_store',
  user_purchased_store = 'user_purchased_store',
  share_bot_button_click = 'share_bot_button_click',
  pwa_popup_show = 'pwa_popup_show',
  pwa_installed = 'pwa_installed',
  pwa_push_activated = 'pwa_push_activated',
  search_initiated = 'search_initiated',
  reaction_sent = 'reaction_sent',
  generate = 'generate'
}

export enum EScreens {
  chatsListScreen = 'chatsListScreen',
  exploreScreen = 'exploreScreen',
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICohortEventPayload { }

export interface IConsentGrantedPayload {
  /** В зависимости от галки про optional cookies */
  cookies_consent: boolean;
  /**  Если пользователь кликнул на Exit */
  ai_18_consent: boolean;
}
export interface IScreenShownPayload {
  currentScreen: `${EScreens}`;
}
export interface ILeaveChatPayload {
  bot_name: string;
  is_personal: boolean;
}
export interface IStartedChatPayload {
  bot_name: string;
  is_personal: boolean;
  /**  Null если по прямой ссылке */
  previousScreen: `${EScreens}` | null;
}
export interface IUserSentMessagePayload {
  bot_name: string;
  is_personal: boolean;
}
export interface IBotSentImagePayload {
  bot_name: string;
  is_personal: boolean;
  reason: string; //'hello_messages'
  picture_generation_type?: 'single_message' | 'dialog_context';
}
export interface IBotSentMessagePayload {
  bot_name: string;
  is_personal: boolean;
  sexting: boolean;
}
export interface IPopUpShownPayload {
  /** Для login_signup pop-up */
  type: 'login_signup' | 'messages_limit' | 'sign-up';
  /** self - гость кликнул на Log in/Sign up;  unblur - гость кликнул на blurred сообщение */
  reason: `${ESubscriptionReason}`;
}
export enum ESubscriptionReason {
  /** по клику на баннер премиума*/
  self = 'self',
  /** по клику на blurred сообщение*/
  unblur = 'unblur',
  /** из флоу создания я кастома*/
  custom_bot = 'custom_bot',
  /** из флоу создания я кастома*/
  custom_bot_generations_limit = 'custom_bot_generations_limit',
  custom_bot_creation_limit = 'custom_bot_creation_limit',

  resubscription = 'resubscription',
 /** пользователь привысил лимит сообщений*/
  hight_demand = 'hight-demand',
  photo_generation = 'photo_generation',
}
export interface ISubscriptionPaywallShownPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  is_personal: boolean;
}
export interface ISubscriptionCheckoutPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  is_personal: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserRegisteredPayload {
  registration_type: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserLoggedInPayload { }

export interface ISubscriptionStarted {
  productId: string;
  revenue: string | number;
  proceeds_usd: string | number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISendMeAPictureButtonClick { }

export enum ECreateChatbotSteps {
  explainer = 'explainer',
  style = 'style',
  sex = 'sex',
  choose_flow = 'choose_flow',
  image_upload = 'image_upload',
  name = 'name',
  origin = 'origin',
  hair = 'hair',
  body = 'body',
  breast = 'breast',
  age = 'age',
  photo = 'photo',
  portrait_generation = 'portrait_generation',
  portrait_limit = 'portrait_limit',
  portrait = 'portrait',
  occupation = 'occupation',
  personality = 'personality',
  sexual_preferences = 'sexual_preferences',
  traits = 'traits',
  chatbot_created = 'chatbot_created',
  custom_persona_facts = 'custom_persona_facts',
}

export interface ICreateChatbotPayload {
  version: '1.0' | '1.1' | '2.0' | '2.1';
  sex?: string;
  style?: `${EBotSessionMode}`;
  step: `${ECreateChatbotSteps}`
  source: 'banner_main' | 'banner_explore' | 'navbar'
}
export enum ECoinsPaywallReason {
  unblur = 'unblur',
  chat_wallet = 'chat_wallet',
  navbar = 'navbar',
  store = 'store',
}
export interface ICoinsPaywallShownPayload {
  bot_name?: string
  is_personal: boolean
  reason: `${ECoinsPaywallReason}`
}
export interface ICoinsPaidPayload {
  quantity: number
  bot_name?: string
  bot_id?:number;
  is_personal: boolean
  reason: `${ECoinsPaywallReason}`
  item:IEcommercePayloadItem;
}
export interface ISubscriptionPaidPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  bot_id?: number;
  is_personal: boolean;
  item: IEcommercePayloadItem
}
export interface IPurchasedUnblurPhotoPayload {
  price: number
  bot_name: string
  is_personal: boolean
}
export interface IUserOpenedStore {
  bot_name: string;
}

export interface IUserPurchasedStore {
  bot_name:string;
  item_name: string;
  item_price: number;
}

export interface IShareBotButtonClick {
  location: 'create_chat_bot' | 'profile_chat_bot'
}


export interface ISearchInitiated {
  value: string;
}


export interface IReactionSent {
  message_kind: 'text' | 'image'
  reaction_type: 'upvote' | 'downvote'
  feedback?: string;
  labels?: string[]
}


export interface IGenerate {
  prompt?: string
  traits?: {
    [key:string]: string;
  }
  input_mode : "prompt" | "traits";
  gender?: `${Generate.EGender}`,
  generation_type?: `${Generate.EGenerationType}`
  mode?: `${Generate.EMode}`,
}