import { boolean } from "yup";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IBot {
  id: number;
  nickname: string;
  first_name: string;
  last_name: string;
  avatar?: string;
  gender: string;
  age: number;
  country: string;
  city: string;
  description: string;
  img01?: string;
  img_p01?: string;
  traits: string[];
  new_custom_traits: {
    image_url: string;
    option: string;
    order_position: number;
    trait: string
  }[]
  mood: {
    shyness: number;
    optimism: number;
    ordinariness: number;
  };
  media: IBotMediaItem[];
  name: string;
  is_chat_exist?: boolean;
  gallery?: string[];
  user_type?: string;
  is_personal?: boolean;
  is_subscribe_required?: boolean;
}
export interface IBotMediaItem {
  type: 'public' | // unblur осуществляется через метод /image-gallery/{image_gallery_id}/unblur
  'personal' // unblur осуществлять через метод POST /message/{message_id}/unblur
  image_gallery_id?: number;
  message_id?: number;
  blurred: boolean;
  image_url: string;
  photo_cost: number
}
export interface IBotCreated extends IBot {
  id: number;
}
export interface IGetBotParams {
  botId: number;
}
export interface ISlider {
  [key: string]: ISliderOption[];
}
export interface ISliderOption {
  id: number;
  step_id: number;
}

export interface ITraitsListItem {
  category: ITraitsListItemCategory;
  traits: ITraitsListItemTrait[];
}

export interface ITraitsListItemCategory {
  slug: string;
  name: string;
}

export interface ITraitsListItemTrait {
  slug: string;
  name: string;
  description: string;
}

export interface ISessionOption {
  value: string | number;
  image_url: string;
}
export interface IGetBotSessionCreateParams {
  has_subscribe: boolean;
  persona_type?: string;
}
export interface ISuggestedPersonaFactsItem {
  fact: string;
  slug: string;
}
export interface IGetBotSessionCreateData {
  create_bot_session_id: string;
  gender: {
    image_size_web: string;
    title: string;
    options: ISessionOption[]
  }
  mode: {
    image_size_web: string;
    title: string;
    options: ISessionOption[]
  }
  suggested_persona_facts: ISuggestedPersonaFactsItem[]
}
export interface IPostBotSessionGenderParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  persona_type?: string;
  gender: string;
}
export interface IPostBotSessionGenderData {
  create_bot_session_id: string;
  name_chooses: string[]
}
export enum EBotSessionMode {
  realistic = 'reaclistic',
  anime = 'anime',
}
export interface IPostBotSessionModeParams {
  create_bot_session_id: string;
  persona_type?: string;
  has_subscribe: boolean;
  mode: `${EBotSessionMode}`
}
export interface IPostBotSessionModeData {
  gender: {
    image_size_web: string;
    title: string;
    options: ISessionOption[]
  }
}

export interface IPostBotSessionNickNameParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  persona_type?: string;
  nickname: string;
}
export interface IPostBotSessionNickNameData {
  create_bot_session_id: string;
  face_traits: {
    [value: string]: {
      title: string;
      image_size_web: string;
      options: ISessionOption[]
      step: number;
    }
  }
  persona_traits: {
    [value: string]: {
      step: number;
      title: string;
      image_size_web: string;
      options: ISessionOption[]
    }
  }
}
export interface IPostBotSessionFaceTraitsParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  face_traits: {
    [value: string]: string | number;
  }
}
export type IPostBotSessionFaceTraitsData = Array<{
  id: number;
  image: string;
}>
export interface IPostBotSessionFaceTraitsError {
  detail: string;
  remaining: number;
  reset_in: number;
}
export interface IPostBotSessionImageParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  image_id: number;
}
export interface IPostBotSessionImageData {
  create_bot_session_id: string;
}
export interface IPostBotSessionPersonaTraitsParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  persona_type?: string;
  persona_traits: {
    [value: string]: string | number;
  }
}
export interface IPostBotSessionPersonaTraitsData extends IBotCreated {
  is_new_custom: boolean
  is_personal: boolean
  is_subscribe_required: boolean
}
export interface IPostBotSessionPersonaFactsParams {
  has_subscribe: boolean
  create_bot_session_id: string;
  persona_type?: string;
  persona_facts: string[]

}
export interface IPostBotSessionPersonaFactsData {
  data: 'ok'
}
export interface IPostBotSessionPersonaFactsErrorDetailItem {
  fact: string;
  moderated_categories: string[]
}
export interface IPostBotSessionPersonaFactsError {
  detail: IPostBotSessionPersonaFactsErrorDetailItem[],
}

export interface IGetBotShareParams {
  share_code: string;
}
export type IGetBotShareData = IBotCreated;

export interface IPostBotShareParams {
  share_code: string;
}
export type IPostBotShareData = IBotCreated;

export interface IPostBotSessionUserImageParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
  image: File;
}
export interface IPostBotSessionUserImageData {
  create_bot_session_id: string;
  suggested_persona_facts: ISuggestedPersonaFactsItem[]
}


export interface IPostBotSessionUserImageCommitParams {
  create_bot_session_id: string;
  has_subscribe: boolean;
}

export interface IPostBotSessionUserImageCommitData extends IBot {
  id: number
}

export interface IPostBotSearchParams {
  nickname: string;
  limit: number;
  offset: number;
}
export interface IPostBotSearchData {
  data: IPostBotSearchDataItems[],
  limit: number,
  offset: number
}
export interface IPostBotSearchDataItems {
  chat_user_id: number;
  total_messages: number;
  nickname: string;
  avatar: string;
  preview: string;
  is_subscribe_required: boolean;
}
export interface IGetBotCategoriesParams {
  limit_per_category?: number;
}
export interface IGetBotCategoriesData {
  categories_mapping: IGetBotCategoriesDataMappingItem[]
  categories: Record<string, IGetBotCategoriesSlugDataItem[]>
}
export interface IGetBotCategoriesDataMappingItem {
  slug: string;
  total_count: number;
  label: string;
  priority: number;
}
export interface IGetBotCategoriesSlugParams {
  category_slug: string;
  limit: number;
  offset: number;
}
export type IGetBotCategoriesSlugData = IGetBotCategoriesSlugDataItem[]
export interface IGetBotCategoriesSlugDataItem {
  chat_user_id: number;
  total_messages: number;
  nickname: string;
  avatar: string;
  preview: string;
  is_subscribe_required: boolean
}

export interface IGetBotRecentParams {
  limit: number;
  offset: number;
}

export type IGetBotRecentData = IGetBotCategoriesSlugDataItem[]

export interface IPostBotDialogParams {
  bot_chat_user_id: number;
  has_subscribe: boolean;
}
export interface IPostBotDialogData {

}