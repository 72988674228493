
export enum EMode {
  REALISTIC = 'REALISTIC',
  AMIME = 'ANIME',
}

export enum EGender {
  male = 'male',
  female = 'female',
}
export enum EGenerationType {
  face = 'face',
  persona = 'persona',
}
export interface IGetConfigParams {
  gender: `${EGender}`
  mode: `${EMode}`
  generation_type: `${EGenerationType}`
}
export interface IGetConfigData {
  [key:string] : {
      options: string[]
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPostUploadImageParams {
  image: File
}
export interface IPostUploadImageData {
  image_id: 3
}

export interface IPostUploadImageError {
  detail: string;
  error_code: string | 'NUDE_DETECTED';
}


export interface IPostTextToImageParams {
  image_id?: number,
  is_public: boolean;
  prompt: string
  mode: `${EMode}`,
  has_subscribe: boolean,
  n_images: number
}
export interface IPostTextToImageError {
  prompt: string;
}
export type IPostTextToImageData = GenerateImageItem[]

export interface IPostTraitsToImageParams {
  image_id?: number,
  is_public: boolean;
  traits: {
    [key:string]: string;
  }
  gender: `${EGender}`,
  generation_type: `${EGenerationType}`
  mode: `${EMode}`,
  has_subscribe: boolean,
  n_images: number
}

interface GenerateImageItem {
  image_id: number;
  image: string;
  is_nude_detected: boolean;
  photo_cost: number;
  blurred: boolean;
}
export type IPostTraitsToImageData  = GenerateImageItem[]



export interface IGetGalleryParams {
  gallery_type: 'public' | 'personal'
  limit: number;
  offset: number;
}
export interface IGetGalleryData {
  data: IGetGalleryDataItem[]
  limit: number;
  offset: number;
  count: number;
}

export interface IGetGalleryDataItem {
  id: number;
  image_url: string;
  is_nude_detected: boolean;
  photo_cost: number;
  blurred: boolean;
}


export interface IPostUnblurParams {
  image_id: number
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPostUnblureData {

}