import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

export const selectors = {
  sliders: createSelector(selectData, (state) => state.sliders),
  traits: createSelector(selectData, (state) => state.traits),
  botCreated: createSelector(selectData, (state) => state.botCreated),
  sessionCreate: createSelector(selectData, (state) => state.createSession.create),
  sessionMode: createSelector(selectData, (state) => state.createSession.mode),
  sessionGender: createSelector(selectData, (state) => state.createSession.gender),
  sessionNickName: createSelector(selectData, (state) => state.createSession.nickName),
  sessionPersonaTraits: createSelector(selectData, (state) => state.createSession.personaTraits),
  sessionFace: createSelector(selectData, (state) => state.createSession.faceTraits),
  sessionResults: createSelector(selectData, (state) => state.createSession.results),
  sessionStep: createSelector(selectData, (state) => state.createSession.step),
  sessionPersonaType: createSelector(selectData, (state) => state.createSession.personaType),
  sessionCommit: createSelector(selectData, (state) => state.createSession.commit),
  sessionPersonaFactsError: createSelector(selectData, (state) => state.createSession.personaFactsError),
  imageProcessingLimit: createSelector(selectData, (state) => state.createSession.imageProcessingLimit),
  shareBotTooltipClosed: createSelector(selectData, (state) => state.shareBotTooltipClosed),
};
